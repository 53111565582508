import { lazy } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TvIcon from '@material-ui/icons/Tv';
import {
  AccountBalance,
  BubbleChart,
  Block,
  Email,
  Sms,
  FilterVintage,
  Star,
  Assignment,
  BarChart,
  Business,
  Navigation,
  Schedule,
  Category,
  CardMembership,
  BusinessCenter,
  ViewList,
  People,
  PeopleOutline,
  Web,
  WebHook,
  Person,
  Casino,
  DateRange,
  Description,
  Toc,
  LocationOn,
  Link,
  GroupWork,
  PlaylistAdd,
  LocalPlay,
  LibraryAdd,
  ViewColumn,
  SupervisedUserCircle,
  ConfirmationNumber,
  InsertChart,
  WatchLater,
  RateReview,
  Restore,
  ShoppingCart,
  Settings,
  WhatsApp
} from '@material-ui/icons';
import Dashboard from 'views/Dashboard/Dashboard.js';
import TvDisplay from 'views/TvDisplay/TvDisplay';
import WizardView from 'views/Wizard/WizardView'

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    //component: WizardView,
    component: Dashboard,
    dataTestId: 'dashboardNavLink',
  },
  { redirect: true, path: '/', pathTo: '/dashboard', name: 'Dashboard' }
];

const tvDisplayRoutes = [
  {
    path: '/display',
    name: 'TV Display',
    icon: TvIcon,
    component: TvDisplay,
    dataTestId: 'tvDisplayNavLink',
  },
  { redirect: true, path: '/', pathTo: '/login', name: 'Login' }
];

const welcomeWizardRoutes = [
  {
    path: '/welcomeWizard',
    name: 'Welcome Wizard',
    icon: DashboardIcon,
    component: WizardView,
    dataTestId: 'welcomeWizard',
  },
  { redirect: true, path: '/', pathTo: '/login', name: 'Login' }
];

const superAdminRoutes = [
  {
    path: '/dashboard/superadmin',
    name: 'Super Admin Dashboard',
    shortName: 'SAD',
    component: lazy(()=> import('views/SuperAdmin/SuperAdminDashboard')),
    icon: DashboardIcon,
    iconColor: 'secondary',
    dataTestId: 'superAdminDashNavLink',
  },
  {
    path: '/organization/superadmin/list',
    name: 'All Organizations List',
    shortName: 'OLS',
    component: lazy(()=> import('views/SuperAdmin/AllOrganizationsList')),
    icon: Business,
    iconColor: 'secondary',
    dataTestId: 'allOrgListNavLink',
  },
  {
    path: '/business-categories',
    name: 'Business Categories',
    shortName: 'BC',
    component: lazy(() => import('views/BusinessCategories/BusinessCategoriesList')),
    icon: ViewList,
    iconColor: 'secondary',
    dataTestId: 'bCategoryNavLink',
  },
  {
    path: '/superadmin/config',
    name: 'Super Admin Preferences',
    shortName: 'NC',
    component: lazy(() => import('views/SuperAdmin/SuperAdminConfiguration')),
    icon: Settings,
    iconColor: 'secondary',
    dataTestId: 'notifConfNavLink',
  },
  {
    path: '/superadmin/customer-reviews',
    name: 'Manage Customer Reviews',
    shortName: 'CR',
    component: lazy(() => import('views/SuperAdmin/ManageCustomerReviews')),
    icon: RateReview,
    iconColor: 'secondary',
    dataTestId: 'custReviewNavLink',
  },
  {
    path: '/superadmin/plans',
    name: 'Manage Subscrption Plans',
    shortName: 'CR',
    component: lazy(() => import('views/SuperAdmin/ManageSubscriptionPlans')),
    icon: CardMembership,
    iconColor: 'secondary',
    dataTestId: 'custReviewNavLink',
  },
]

const managementRoutes = [
  {
    path: '/services/list',
    name: 'Manage Services',
    shortName: 'MS',
    component: lazy(() => import('views/Services/ServicesList')),
    icon: BusinessCenter,
    iconColor: 'secondary',
    dataTestId: 'serviceListNavLink',
  },
  {
    path: '/products',
    name: 'Manage Products',
    shortName: 'PS',
    icon: ShoppingCart,
    component: lazy(() => import('views/Products/ProductsList')),
    dataTestId: 'productsNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/product-categories',
    name: 'Product Categories',
    shortName: 'PC',
    icon: Category,
    component: lazy(() => import('views/ProductCategories/ProductCategories')),
    dataTestId: 'productCategoriesNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/provider/list',
    name: 'Manage Users',
    shortName: 'PD',
    component: lazy(() => import('views/Provider/ProviderList')),
    icon: People,
    iconColor: 'secondary',
    dataTestId: 'providerListNavLink',
  },
  {
    path: '/location/list',
    name: 'Locations',
    shortName: 'BL',
    component: lazy(() => import('views/Location/LocationList')),
    icon: LocationOn,
    iconColor: 'secondary',
    dataTestId: 'locationListNavLink',
  },
  {
    path: '/location-groups',
    name: 'Location Groups',
    shortName: 'LG',
    icon: GroupWork,
    component: lazy(() => import('views/LocationGroups/LocationGroups')),
    dataTestId: 'LocationGroupssNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/counter/list',
    name: 'Counters',
    shortName: 'CL',
    icon: Casino,
    component: lazy(() => import('views/Counters/CounterList.js')),
    iconColor: 'secondary',
    dataTestId: 'counterListNavLink',
  },
  {
    path: '/assessments',
    name: 'Assessments',
    shortName: 'AS',
    icon: Assignment,
    component: lazy(() => import('views/Surveys/Assessments')),
    dataTestId: 'assessmentsNavLink',
    iconColor: 'secondary'
  },
  {
    path: '/service-categories',
    name: 'Service Categories',
    shortName: 'SC',
    component: lazy(() => import('views/ServiceCategories/ServiceCategoriesList')),
    icon: Category,
    iconColor: 'secondary',
    dataTestId: 'sCategoryNavLink',
  },
  {
    path: '/email-templates',
    name: 'Email Templates',
    shortName: 'ET',
    icon: Email,
    component: lazy(() => import('views/EmailTemplates/EmailTemplates')),
    dataTestId: 'emailTemplatesNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/sms-templates',
    name: 'SMS Templates',
    shortName: 'ET',
    icon: Sms,
    component: lazy(() => import('views/SmsTemplates/SmsTemplates')),
    dataTestId: 'smsTemplatesNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/web-integration',
    name: 'Web Integration',
    shortName: 'ET',
    icon: Web,
    component: lazy(() => import('views/WebIntegration/WebIntegration')),
    dataTestId: 'webIntegrationNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/payment-config',
    name: 'Payment Configuration',
    shortName: 'ET',
    icon: AccountBalance,
    component: lazy(() => import('views/PaymentConfiguration/PaymentConfiguration')),
    dataTestId: 'PaymentConfigNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/whatsapp-integration',
    name: 'WhatsApp Business',
    shortName: 'ET',
    icon: WhatsApp,
    component: lazy(() => import('views/WhatsAppIntegration/WhatsAppIntegration')),
    dataTestId: 'webIntegrationNavLink',
    iconColor: 'secondary',
  },
  {
    path: '/activity-logs',
    name: 'Activity Logs',
    shortName: 'AL',
    icon: Restore,
    component: lazy(() => import('views/ActivityLogs/ActivityLogs')),
    dataTestId: 'activityLogsNavLink',
    iconColor: 'secondary',
  },
];

const operationRoutes = [
  {
    path: '/customer-service',
    name: 'Customer Service',
    shortName: 'PD',
    icon: FilterVintage,
    component: lazy(() => import('views/CustomerService/CustomerService.js')),
    dataTestId: 'customerServiceNavLink',
    iconColor: 'primary',
  },
  {
    path: '/event-list',
    name: 'Bookings',
    shortName: 'EV',
    icon: ConfirmationNumber,
    component: lazy(() => import('views/Events/EventList')),
    dataTestId: 'tmpServicesNavLink',
    iconColor: 'primary',
  },
  {
    path: '/booking',
    name: 'Create Booking',
    shortName: 'BK',
    icon: LibraryAdd,
    component: lazy(() => import('views/Bookings/Booking.js')),
    dataTestId: 'tmpServicesNavLink',
    iconColor: 'primary',
  },
  {
    path: '/provider/dashboard',
    name: 'Provider Dashboard',
    shortName: 'PDD',
    icon: DashboardIcon,
    component: lazy(() => import('views/Provider/Dashboard')),
    dataTestId: 'providerDahsboardNavLink',
    iconColor: 'primary',
  },
  {
    path: '/counter/dashboard',
    name: 'Counter Dashboard',
    shortName: 'CD',
    icon: ViewColumn,
    component: lazy(() => import('views/Counters/CounterDashboard.js')),
    iconColor: 'primary',
  },
  {
    path: '/tmp-services',
    name: 'Scheduled Services',
    shortName: 'TS',
    icon: WatchLater,
    component: lazy(() => import('views/scheduledServices/ScheduledServicesList')),
    dataTestId: 'tmpServicesNavLink',
    iconColor: 'primary',
  },
  {
    path: '/calendar',
    name: 'Manage Calendar',
    shortName: 'MC',
    icon: DateRange,
    component: lazy(() => import('views/Calendar/ManageCalendar')),
    dataTestId: 'calendarNavLink',
    iconColor: 'primary',
  },
  /*
  {
    path: '/chart',
    name: 'Chart',
    shortName: 'CH',
    icon: InsertChart,
    component: lazy(() => import('views/Chart/ChartBoard')),
    dataTestId: 'chartNavLink',
    iconColor: 'primary',
  },
  */
  {
    path: '/reports',
    name: 'Reports',
    shortName: 'RP',
    icon: Description,
    component: lazy(() => import('views/Reports/Reports')),
    dataTestId: 'reportsNavLink',
    iconColor: 'primary',
  },
  {
    path: '/manage-urls',
    name: 'Manage URLs',
    shortName: 'RP',
    icon: Link,
    component: lazy(() => import('views/ManageURLs/ManageURLs')),
    dataTestId: 'mangeUrlNavLink',
    iconColor: 'primary',
  },
  {
    path: '/feedback-surveys',
    name: 'Customer Feedbacks',
    shortName: 'FS',
    icon: Description,
    component: lazy(() => import('views/Feedbacks/Feedbacks')),
    dataTestId: 'FeedbackNavLink',
    iconColor: 'primary',
  },
  {
    path: '/blacklisted-customers',
    name: 'Blacklisted Customers',
    shortName: 'BC',
    icon: Block,
    component: lazy(() => import('views/BlackListedCustomers/BlackListedCustomers')),
    dataTestId: 'BlackListedNavLink',
    iconColor: 'primary',
  },
]

const otherRoutes = [
  {
    path: '/location/edit/:id',
    name: 'Location Edit',
    component: lazy(() => import('views/Location/LocationEdit')),
  },
  {
    path: '/location/create',
    name: 'Location Create',
    component: lazy(() => import('views/Location/LocationCreate')),
  },
  {
    path: '/provider/create',
    name: 'Provider Create',
    component: lazy(() => import('views/Provider/ProviderCreate')),
  },
  {
    path: '/provider/edit/:id',
    name: 'Provider Edit',
    component: lazy(() => import('views/Provider/ProviderEdit')),
  },
  {
    path: '/admin/create',
    name: 'Admin Create',
    component: lazy(() => import('views/Admin/AdminCreate')),
  },
  {
    path: '/admin/edit/:id',
    name: 'Admin Edit',
    component: lazy(() => import('views/Admin/AdminEdit')),
  },
  {
    path: '/organization/create',
    name: 'Create Organization',
    component: lazy(() => import('views/Organization/OrganizationCreate')),
  },
  {
    path: '/organization/edit/:id',
    name: 'Organization Edit',
    component: lazy(() => import('views/Organization/OrganizationEdit.js')),
  },
  {
    path: '/orgaization/superadmin/edit/:id',
    name: 'Super Admin Organization Edit',
    component: lazy(() => import('views/Organization/SuperAdminOrgEdit')),
  },
  {
    path: '/services/create',
    name: 'Create Services',
    component: lazy(() => import('views/Services/ServiceCreate')),
  },
  {
    path: '/service/edit/:id',
    name: 'Service Edit',
    component: lazy(() => import('views/Services/ServiceEdit')),
  },
  {
    path: '/product/create',
    name: 'Add Product',
    component: lazy(() => import('views/Products/ProductAddEdit')),
  },
  {
    path: '/product/edit/:productId',
    name: 'Product Edit',
    component: lazy(() => import('views/Products/ProductAddEdit')),
  },
  {
    path: '/location-group/create',
    name: 'Add Location Group',
    component: lazy(() => import('views/LocationGroups/LocationGroupAddEdit')),
  },
  {
    path: '/location-group/edit/:locationGroupId',
    name: 'Location Group Edit',
    component: lazy(() => import('views/LocationGroups/LocationGroupAddEdit')),
  },
  {
    path: '/schedule-services/create',
    name: 'Create Schedule Service',
    component: lazy(() => import('views/scheduledServices/ScheduledServiceCreate')),
  },
  {
    path: '/schedule-service/edit/:id',
    name: ' Edit Schedule Service',
    component: lazy(() => import('views/scheduledServices/ScheduledServiceEdit')),
  },
  {
    path: '/schedule-service/clone/:id',
    name: ' Create Schedule Service',
    component: lazy(() => import('views/scheduledServices/ScheduledServiceEdit')),
  },
  {
    path: '/register',
    name: 'Register Page',
    shortName: 'Register',
    mini: 'RP',
    component: lazy(() => import('views/Auth/RegisterPage')),
  },
  {
    path: '/email-templates/edit/:id',
    name: 'Edit Email Template',
    shortName: 'Edit Email Template',
    component: lazy(() => import('views/EmailTemplates/EditEmailTemplate')),
  },
  {
    path: '/email-templates/create',
    name: 'Create Email Template',
    shortName: 'Create Template',
    component: lazy(() => import('views/EmailTemplates/EditEmailTemplate')),
  },
  {
    path: '/availability/detail/:id',
    name: 'Availability Detail',
    shortName: 'Availability Detail',
    component: lazy(() => import('views/AvailabilitySlots/AvailabilitySlotsList')),
  },
  {
    path: '/schedule-service/detail/:id',
    name: 'Schedule Details',
    shortName: 'Schedule Detail',
    component: lazy(() => import('views/scheduledServices/ScheduledServicesDetail')),
  },
  {
    path: '/schedule-report/detail/:id',
    name: 'Schedule Report Detail',
    shortName: 'Schedule Report Detail',
    component: lazy(() => import('views/ScheduleReport/ScheduleReportList')),
  },
  {
    path: '/assessments/new',
    name: 'Create new assessment',
    shortName: 'Create new assignment',
    component: lazy(() => import('views/Surveys/CreateAssessment')),
  },
  {
    path: '/survey/event/:eventId',
    name: 'Survey',
    shortName: 'Survey',
    component: lazy(() => import('views/SurveyAnswers/ResponseSurvey')),
  },
];

const providerRoutes = [
  '/calendar', '/profile', '/dashboard',
  '/tmp-services',  '/schedule-services/create', '/schedule-service/edit/:id',
  '/availability/detail/:id', '/schedule-report/detail/:id',
  '/customer-service', '/provider/dashboard', '/counter/dashboard', '/feedback-surveys', '/blacklisted-customers'
];

const profileRouteComponent = {
  path: '/profile',
  name: 'Profile',
  component: lazy(() => import('views/Profile/profile.js')),
  icon: Person,
  iconColor: 'error',
  dataTestId: 'profileNavLink',
};

export {
  dashboardRoutes,
  otherRoutes,
  providerRoutes,
  profileRouteComponent,
  superAdminRoutes,
  managementRoutes,
  operationRoutes,
  tvDisplayRoutes,
  welcomeWizardRoutes
};
