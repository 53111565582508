import React from 'react';
import ProviderCreate from './WizardSteps/ProviderCreate';
import Button from 'components/CustomButtons/Button';
import WizardAccordion from 'components/Accordion/WizardAccordion';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

class WizardProviders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			providerIndexes: [1],
			error:''
		}
		this.providerComponentInstances = [];
		this.providerResponses = [];
	}

	removeProvider(providerIndex) {
		const indexesArray = this.state.providerIndexes;
		this.providerComponentInstances = this.providerComponentInstances.filter( (arr,index)=> index !== providerIndex );
		let newIndexArray = indexesArray.filter(arr => arr !== providerIndex );
		this.setState({ providerIndexes: newIndexArray });
		console.log("this.providerComponentInstances >>",this.providerComponentInstances)
		
	}

	addProvider() {
		const indexesArray = this.state.providerIndexes;
		const len = indexesArray.length;
		let nextIndex = 1;
		if (len > 0) {
			nextIndex = indexesArray[len - 1] + 1;
		}
		indexesArray.push(nextIndex);
		this.setState({ providerIndexes: indexesArray });
		console.log("this.providerComponentInstances >>",this.providerComponentInstances)
	}


	wizardSubmit = async (allStates) => {
		console.log("Provider wizard submit");
		this.setState({error:""})
		let providerPayloadArray = []
		for (const providerComponentInstance of this.providerComponentInstances) {
			if( providerComponentInstance?.wizardSubmit ){
				const payload = await providerComponentInstance.wizardSubmit(allStates)
				if (payload) {
					const email = payload.email.toLowerCase().trim()
					const duplicateEmailEntry = providerPayloadArray.filter(e => e?.email?.toLowerCase()?.trim() == email )
					if(duplicateEmailEntry?.length){
						this.setState({error:"Duplicate Email Entry. Cannot add multiple Provider with same email"})
						return false;
					}
					providerPayloadArray.push(payload);

					
				}else{
					return false
				}
			}
		}
		let updatedStates = {
            ...allStates,
            providerPayloadArray: providerPayloadArray
        }
        return updatedStates;
	}

	render() {
		this.providerComponentInstances = [];
		const collapsesValue = this.state.providerIndexes.map((providerIndex, index) => {
			return {
				title: `User ${providerIndex}`,
				content: (
					<GridContainer style={{ border: "1px solid rgb(226, 220, 220)" }}>
						<GridItem md={12}>
							<ProviderCreate isWizard={true} allStates={this.props.allStates} ref={(node) => {
								if (node != null) {
									this.providerComponentInstances[providerIndex] = node;
								}
							}} />
							{ providerIndex > 1 && ( (index+1) == this.state.providerIndexes.length) && <Button size="sm" color="rose" onClick={() => { this.removeProvider(providerIndex) }}>
								Remove User
							</Button>}
						</GridItem>
					</GridContainer>
				)
			}
		});
		return (
			<div>
				<WizardAccordion
					collapses={collapsesValue}
				/>
				<GridContainer justifyContent={"flex-start"}>
					<GridItem>
						{this.state.providerIndexes?.length >= 5 ? null :
						<Button size="sm" color="rose" onClick={() => { this.addProvider() }} style={{float:'right'}}>
							Add User
						</Button>}
					</GridItem>
				</GridContainer>
				<div style={{textAlign:'center',color:'red'}}>{this.state.error}</div>
			</div>
		);
	}
}
export default WizardProviders;