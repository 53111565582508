import { organization } from '../constants/Organization.constants';

const initialState = {
  organizations: [],
  fetchOrganizationsLoading: null,
  fetchOrganizationsError: false,

  organization: {},
  createOrganizationError: null,
  createOrganizationLoading: false,

  editOrganizationError: null,
  editOrganizationLoading: false,

  fetchOrganizationLoading: false,
  fetchOrganizationError: null, 
  
  zoomCredentials: {},
  paymentMethodOptions: [],
  paymentMethods: {},
  planDetails:null,

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case organization.CREATE_ORGANIZATION_LOADING:
      return { ...state, createOrganizationLoading: true };
    case organization.CREATE_ORGANIZATION_SUCCESS:
      return { ...state, organization: action.payload, createOrganizationLoading: false, planDetails: action?.payload?.planDetails };
    case organization.CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        createOrganizationError: action.payload.error,
        createOrganizationLoading: false
      };

    case organization.EDIT_ORGANIZATION_LOADING:
      return { ...state, fetchOrganizationLoading: true };
    case organization.EDIT_ORGANIZATION_SUCCESS:
      return { ...state, organization: action.payload, fetchOrganizationLoading: false, planDetails: action?.payload?.planDetails };
    case organization.EDIT_ORGANIZATION_FAILURE:
      return {
        ...state,
        editOrganizationError: action.payload.error,
        fetchOrganizationLoading: false
      };

    case organization.FETCH_ORGANIZATIONS_LOADING:
      return { ...state, fetchOrganizationsLoading: true };
    case organization.FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload.organizations,
        planDetails: action.payload?.organizations?.length && action.payload?.organizations[0]?.planDetails? action.payload?.organizations[0]?.planDetails : null,
        fetchOrganizationsLoading: false,
        fetchOrganizationsError: false,
      };
    case organization.FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        fetchOrganizationsError: action.payload.error,
        fetchOrganizationsLoading: false
      };

    case organization.FETCH_ORGANIZATION_LOADING:
      return { ...state, fetchOrganizationLoading: true };
    case organization.FETCH_ORGANIZATION_SUCCESS:
      return { ...state, organization: action.payload, fetchOrganizationLoading: false, planDetails: action?.payload?.planDetails };
    case organization.FETCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        fetchOrganizationError: action.payload.error,
        fetchOrganizationLoading: false
      };
    case organization.SET_ZOOM_CREDENTIALS:
      return {
        ...state,
        zoomCredentials: action.payload,
      };
    case organization.SET_ORG_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case organization.SET_ORG_PAYMENT_METHODS_OPTIONS:
      return {
        ...state,
        paymentMethodOptions: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
