
import axios from 'axios';
import { get } from 'lodash';
import { URL } from 'config/config';
import { showAlert } from './alert';
import { fetchOrganizationsBySuperAdmin } from './organization';

export const SET_SUPER_ADMIN_PREFERENCES = 'SET_SUPER_ADMIN_PREFERENCES';
export const SET_SUPER_ADMIN_PREFERENCES_LOADING = 'SET_SUPER_ADMIN_PREFERENCES_LOADING';

export const SET_NOTIF_LISTS = 'SET_NOTIF_LISTS';
export const SET_NOTIF_LISTS_LOADING = 'SET_NOTIF_LISTS_LOADING';

export const SET_FILTERED_CUSTOMER_REVIEWS = 'SET_FILTERED_CUSTOMER_REVIEWS';
export const SET_FILTERED_CUSTOMER_REVIEWS_LOADING = 'SET_FILTERED_CUSTOMER_REVIEWS_LOADING';

export const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS';
export const SET_SUBSCRIPTION_PLANS_LOADING = 'SET_SUBSCRIPTION_PLANS_LOADING';

const setSuperAdminPreferences = payload => ({
    type: SET_SUPER_ADMIN_PREFERENCES,
    payload
});
const setSuperAdminPreferencesLoading = (value = true) => ({
    type: SET_SUPER_ADMIN_PREFERENCES_LOADING,
    payload: value
});
const setNotificationLists = payload => ({
    type: SET_NOTIF_LISTS,
    payload
});
const setNotificationListsLoading = (value = true) => ({
    type: SET_NOTIF_LISTS_LOADING,
    payload: value
});
const setFilteredCustomerReviews = payload => ({
    type: SET_FILTERED_CUSTOMER_REVIEWS,
    payload
});
const setFilteredCustomerReviewsLoading = (value = true) => ({
    type: SET_FILTERED_CUSTOMER_REVIEWS_LOADING,
    payload: value
});
const setSubscriptionPlans = (payload) => ({
    type: SET_SUBSCRIPTION_PLANS,
    payload: payload
});
const setSubscriptionPlansLoading = (value = true) => ({
    type: SET_SUBSCRIPTION_PLANS_LOADING,
    payload: value
});




export const fetchSuperAdminPreferences = () => async dispatch => {
    try {
        dispatch(setSuperAdminPreferencesLoading(true));
        const response = await axios.get(URL.SUPERADMIN_PREFERENCES)
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(setSuperAdminPreferences(data));
            dispatch(setSuperAdminPreferencesLoading(false));
            return data
        }
        dispatch(setSuperAdminPreferencesLoading(false));
    } catch (err) {
        dispatch(setSuperAdminPreferencesLoading(false));
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
};

export const updateSuperAdminPreferences = (payload) => async dispatch => {
    try {
        dispatch(setSuperAdminPreferencesLoading(true));
        const response = await axios.post(URL.SUPERADMIN_PREFERENCES, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(setSuperAdminPreferences(data))
            dispatch(setSuperAdminPreferencesLoading(false));
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
        dispatch(setSuperAdminPreferencesLoading(false));
    } catch (err) {
        dispatch(setSuperAdminPreferencesLoading(false));
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const fetchNotificationLists = () => async dispatch => {
    try {
        dispatch(setNotificationListsLoading(true));
        const response = await axios.get(`${URL.NOTIF_LIST}`);
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(setNotificationLists(data));
            dispatch(setNotificationListsLoading(false));
            return data
        }
        dispatch(setNotificationListsLoading(false));
    } catch (err) {
        dispatch(setNotificationListsLoading(false));
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const createNotificationList = (payload) => async dispatch => {
    try {
        const response = await axios.post(`${URL.NOTIF_LIST}`, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(fetchNotificationLists())
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const editNotificationList = (payload) => async dispatch => {
    try {
        const response = await axios.put(`${URL.NOTIF_LIST}`, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(fetchNotificationLists())
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const deleteNotificationList = (listId) => async dispatch => {
    try {
        const response = await axios.delete(`${URL.NOTIF_LIST}` + `/${listId}`);
        if (response && response.data.success) {
            dispatch(fetchNotificationLists())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}


export const fetchCustomerReviewsByStatus = (status, pagination) => async dispatch => {
    try {
        dispatch(setFilteredCustomerReviewsLoading(true));
        const response = await axios.get(`${URL.SUPERADMIN_REVIEWS}-by-status?moderationStatus=${status}&${pagination}`);
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(setFilteredCustomerReviews(data));
            dispatch(setFilteredCustomerReviewsLoading(false));
            return response?.data
        }
        dispatch(setFilteredCustomerReviewsLoading(false));
    } catch (err) {
        dispatch(setFilteredCustomerReviewsLoading(false));
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const updateCustReviewModerationStatus = (rewiewIds = [], status = "APPROVED", currentFilter="PENDING") => async dispatch => {
    try {
        const payload = {
            moderationStatus: status,
            reviewIds: rewiewIds
        }
        const response = await axios.put(`${URL.SUPERADMIN_REVIEWS}/approve`, payload);
        if (response && response.data.success) {
            dispatch(showAlert('success', "Customer review status updated!!"));
            const data = get(response, 'data.object', {});
            dispatch(fetchCustomerReviewsByStatus(currentFilter))
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const fetchSubscriptionPlans = (incluedCustom) => async dispatch => {
    try {
        dispatch(setSubscriptionPlansLoading(true));
        const response = await axios.get(`${URL.SUBSCRIPTION_PLANS}${incluedCustom?'?includeCustom=true':''}`)
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(setSubscriptionPlans(data));
            dispatch(setSubscriptionPlansLoading(false));
            return data
        }
        dispatch(setSubscriptionPlansLoading(false));
    } catch (err) {
        dispatch(setSubscriptionPlansLoading(false));
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
};

export const createSubscriptionPlan = (payload) => async dispatch => {
    try {
        const response = await axios.post(`${URL.SUBSCRIPTION_PLANS}`, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(fetchSubscriptionPlans(true))
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const editSubscriptionPlan = (payload) => async dispatch => {
    try {
        const response = await axios.put(`${URL.SUBSCRIPTION_PLANS}`, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(fetchSubscriptionPlans(true))
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const deleteSubscriptionPlan = (planId) => async dispatch => {
    try {
        const response = await axios.delete(`${URL.SUBSCRIPTION_PLANS}` + `/${planId}`);
        if (response && response.data.success) {
            dispatch(fetchSubscriptionPlans(true))
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const assignOrganizationPlan = ( orgId, planId, endDate, trialEndDate) => async dispatch => {
    try {
        const payload = {
            orgId : orgId,
            planId : planId,
            subscriptionEndDate : endDate,
            trialEndDate: trialEndDate
        }
        const response = await axios.put(`org-plan`, payload);
        if (response && response.data.success) {
            dispatch(fetchOrganizationsBySuperAdmin())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}