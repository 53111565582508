// IN , IN_TEST, AU, AU_TEST, LOCAL
export const REGION =
  /.*testonboarding.quezone.com.au/.test(window.location.hostname) ?
    "AU_TEST"
    :
    /.*onboarding.quezone.com.au/.test(window.location.hostname) ?
      "AU"
      :
      /.*testing.provider.que.zone/.test(window.location.hostname) ?
        "IN_TEST"
        :
        /.*provider.que.zone/.test(window.location.hostname) ?
          "IN"
          :
          "IN_TEST";
          
export const REGION_CONFIG = {
  IN: {
    AWS_CONFIG: {
      Auth: {
        identityPoolId: 'ap-south-1:a831b26a-e5ad-41a8-888b-e1d7f9f9fa63',
        region: 'ap-south-1',
        identityPoolRegion: 'ap-south-1',
        userPoolId: 'ap-south-1_gWBKGITKA',
        userPoolWebClientId: 'b86go3nlndo5td74but3uvaaq',
        mandatorySignIn: true
      },
      Analytics: {
        disabled: process.env.NODE_ENV === 'development',
      }
    },
    API_ROOT: "https://api.que.zone/api/",
    CUSTWEB_ROOT: "https://que.zone/",
    API_MEDIA_URL: "https://communication.que.zone/api/media/medias",
    PROD_REDIRECT_URI: "https://api.que.zone",
    API_SUBSCRIBE_EVENTS: "https://api.que.zone/message",
    API_SUBSCRIBE_EVENTS_BOARD: "https://api.que.zone/messageBoard",
    DEFAULT_TIMEZONE : "Asia/Kolkata",
  },
  IN_TEST: {
    AWS_CONFIG: {
      Auth: {
        identityPoolId: 'ap-south-1:baa80567-968e-4acb-8a13-93037b755fee',
        region: 'ap-south-1',
        identityPoolRegion: 'ap-south-1',
        userPoolId: 'ap-south-1_aOLig7gl2',
        userPoolWebClientId: '2abgruc8iq5bva522hb1u0pood',
        mandatorySignIn: true
      },
      Analytics: {
        disabled: process.env.NODE_ENV === 'development',
      }
    },
    API_ROOT: "https://apitesting.que.zone/api/",
    CUSTWEB_ROOT: "https://testing.que.zone/",
    API_MEDIA_URL: "https://communicationtesting.que.zone/api/media/medias",
    PROD_REDIRECT_URI: "https://apitesting.que.zone",
    API_SUBSCRIBE_EVENTS: "https://apitesting.que.zone/message",
    API_SUBSCRIBE_EVENTS_BOARD: "https://apitesting.que.zone/messageBoard",
    DEFAULT_TIMEZONE : "Asia/Kolkata",
  },
  AU: {
    AWS_CONFIG: {
      Auth: {
        identityPoolId: 'ap-southeast-2:adab2657-684c-4222-a17a-9a82b6a5ee84',
        region: 'ap-southeast-2',
        identityPoolRegion: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_0sAegznUX',
        userPoolWebClientId: '3ov1blo2eji4acnqfcv88tcidn',
        mandatorySignIn: true
      },
      Analytics: {
        disabled: process.env.NODE_ENV === 'development',
      }
    },
    API_ROOT: "https://api.quezone.com.au/api/",
    CUSTWEB_ROOT: "https://custweb.quezone.com.au/",
    API_MEDIA_URL: "https://communication.quezone.com.au/api/media/medias",
    PROD_REDIRECT_URI: "https://api.quezone.com.au",
    API_SUBSCRIBE_EVENTS: "https://api.quezone.com.au/message",
    API_SUBSCRIBE_EVENTS_BOARD: "https://api.quezone.com.au/messageBoard",
    DEFAULT_TIMEZONE : "Australia/Sydney",
  },
  AU_TEST: {
    AWS_CONFIG: {
      Auth: {
        identityPoolId: 'ap-southeast-2:adab2657-684c-4222-a17a-9a82b6a5ee84',
        region: 'ap-southeast-2',
        identityPoolRegion: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_0sAegznUX',
        userPoolWebClientId: '3ov1blo2eji4acnqfcv88tcidn',
        mandatorySignIn: true
      },
      Analytics: {
        disabled: process.env.NODE_ENV === 'development',
      }
    },
    API_ROOT: "https://apitesting.quezone.com.au/api/",
    CUSTWEB_ROOT: "https://testcustweb.quezone.com.au/",
    API_MEDIA_URL: "https://communicationtesting.quezone.com.au/api/media/medias",
    PROD_REDIRECT_URI: "https://apitesting.quezone.com.au",
    API_SUBSCRIBE_EVENTS: "https://apitesting.quezone.com.au/message",
    API_SUBSCRIBE_EVENTS_BOARD: "https://apitesting.quezone.com.au/messageBoard",
    DEFAULT_TIMEZONE : "Australia/Sydney",
  },
  LOCAL: {
    
    AWS_CONFIG: {
      /* TEST */
      /*  */
      Auth: {
        identityPoolId: 'ap-south-1:baa80567-968e-4acb-8a13-93037b755fee',
        region: 'ap-south-1',
        identityPoolRegion: 'ap-south-1',
        userPoolId: 'ap-south-1_aOLig7gl2',
        userPoolWebClientId: '2abgruc8iq5bva522hb1u0pood',
        mandatorySignIn: true
      },
      Analytics: {
        disabled: process.env.NODE_ENV === 'development',
      },
      /* */
      /* AU  */
      /* 
      Auth: {
        identityPoolId: 'ap-southeast-2:adab2657-684c-4222-a17a-9a82b6a5ee84',
        region: 'ap-southeast-2',
        identityPoolRegion: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_0sAegznUX',
        userPoolWebClientId: '3ov1blo2eji4acnqfcv88tcidn',
        mandatorySignIn: true
      },
      Analytics: {
        disabled: process.env.NODE_ENV === 'development',
      },
      */
    },
    
    API_ROOT: "http://localhost:8080/api/",
    CUSTWEB_ROOT: "http://localhost:3001/",
    API_MEDIA_URL: "https://communicationtesting.que.zone/api/media/medias",
    PROD_REDIRECT_URI: "http://localhost:8080/api/",
    API_SUBSCRIBE_EVENTS: "http://localhost:8080/message",
    API_SUBSCRIBE_EVENTS_BOARD: "http://localhost:8080/messageBoard",
    DEFAULT_TIMEZONE : "Asia/Kolkata",
  }
}

export const AWS_CONFIG = REGION_CONFIG[REGION].AWS_CONFIG;

export const API_ROOT = REGION_CONFIG[REGION].API_ROOT;
export const CUSTWEB_ROOT = REGION_CONFIG[REGION].CUSTWEB_ROOT;
export const API_SURVEY = 'surveys';
export const API_MEDIA_URL = REGION_CONFIG[REGION].API_MEDIA_URL;
export const URL = {
  ORGANIZATION_NAME_VALIDATE: 'validate?name=',
  ORGANIZATION: 'organizations',
  SET_ORGANIZATION_LOCATION: 'geo-locations',
  FETCH_BUSINESS_CATEGORIES: 'business-categories',
  FETCH_ORGANIZATIONS_BY_BUSINESS_ADMIN_ID: 'organizations-by-business-admin-id',
  FETCH_ORGANIZATIONS_BY_SUPER_ADMIN: 'organizations-by-super-admin',
  FETCH_ORGANIZATIONS_OPTION_BY_BUSINESS_ADMIN_ID: 'organizations-option-by-business-admin-id',

  FETCH_SERVICES_BY_BUSINESS_ADMIN_ID: 'services-by-business-admin-id',

  FETCH_SERVICES_OPTION_BY_BUSINESS_ADMIN_ID: 'services-option-by-business-admin-id',
  SERVICE: 'services',
  FIND_SERVICES_BY_PROVIDER_ID: 'services-by-provider-id',
  FETCH_SURVEY_BY_SURVEY_ID: 'surveys/temp-service',
  FETCH_SURVEY_BY_TEMPORARY_SERVICE_ID: 'surveys/temp-service',
  FETCH_SERVICE_CATEGORIES: 'service-categories',
  FETCH_SERVICE_CATEGORIES_BY_BUSINESS_ADMIN_ID: 'service-categories/business-admin',
  SAVE_SERVICE_CATEGORY_ORDER: 'save-service-categories',

  GEO_LOCATIONS: 'geo-locations',
  GEO_LOCATION_OPTIONS: 'geo-locations-option',

  USER: 'users',
  PROVIDER: 'providers',
  AWS_USER: 'aws-users',
  RESET_PASSWORD: 'users/reset-password',
  ADMIN_RESET_PASSWORD: 'users/reset-default-password',
  CHANGE_PASSWORD: 'users/change-password',
  FORCE_CHANGE_PASSWORD: 'users/completeNewPasswordChallenge',
  TIMEZONE: 'timezones',
  TIMEZONE_OPTIONS: 'timezones-option',
  FETCH_PROVIDERS_OPTION_BY_ORG_ID: 'providers-option-by-org-id',
  FETCH_PROVIDERS_BY_ORG_ID: 'providers-by-org-id',
  FETCH_PROVIDERS_OPTION_BY_SERVICE_ID: 'providers-by-service-id',
  FETCH_PROVIDERS_OPTION_BY_SERVICE_PROVIDER_ID: 'providers-option-by-service-provider-id',
  FETCH_PROVIDERS_OPTION_BY_BUSINESS_ADMIN_ID: 'providers-option-by-business-admin-id',
  FETCH_PROVIDERS_OPTION_BY_BUSINESS_ADMIN_ID_DISPLAY: 'providers-option-by-business-admin-id-display',
  FETCH_PROVIDERS_BY_BUSINESS_ADMIN_ID: 'providers-by-business-admin-id',
  FETCH_PROVIDERS_BY_SUPER_ADMIN: 'providers-by-super-admin',
  FETCH_CUSTOMERS_BY_SUPER_ADMIN: 'customers-by-super-admin',
  FETCH_ADMINS_BY_BUSINESS_ADMIN_ID: 'admins-by-business-admin-id',
  ADMIN_CREATE_AWS_USER: 'users/admin-create-new-users',

  FIND_NORMAL_EVENTS_BY_PROVIDER_ID: 'find-normal-events-by-provider-id',
  FIND_APPOINTMENTS_CUSTOMER_EVENTS_BY_PROVIDER_ID: 'find-appointment-customer-events-by-provider-id',
  FIND_TMP_EVENTS_BY_PROVIDER_ID: 'find-events-calendar-by-provider-id',
  NORMAL_EVENT: 'normal-events',
  NORMAL_EVENT_OVERLAP: 'normal-events-overlap-validate',
  CANCEL_EVENTS: 'events/cancel-all',
  NEW_TMP_SERVICE: 'temporary-services',
  APPOINTMENTS_CUSTOMER_EVENT: 'appointment-customer-events',

  FIND_TMP_SERVICES_BY_BUSINESS_ID: 'find-temporary-services-by-business-admin-id',
  FIND_TMP_SERVICES_BY_PROVIDER_ID: 'find-temporary-services-by-provider-id',
  FIND_TMP_SERVICES_BY_SERVICE_ID: 'find-temporary-services-by-service-id',
  FIND_TMP_SERVICE_DETAIL_BY_TMP_SERVICE_ID: 'temporary-services',

  FIND_AVAILABILITY_BY_TMP_SERVICE: 'availabilities/temporary/service',
  FIND_AVAILABILITY_BY_TMP_SERVICE_ONBOARDING: 'availabilities/temporary/serviceonboard',
  FIND_AVAILABILITY_BY_SERVICE_AND_PROVIDER: 'availabilities-service-provider',

  FIND_SCHEDULE_REPORT_BY_TMP_SERVICE: 'availabilities/temporary/report',

  VERIFY_BOOKING_CODE: 'verify',
  VERIFY_BY_PHONENUMBER: 'verify/phone-number',
  UPDATE_CUSTOMER_FLOW_STATUS: 'customer/flow/status',
  FETCH_CUSTOMER_FLOW_BOARD: 'customer/flow/board',
  FETCH_QUEUE_FOR_PROVIDER: 'liveUpdates/ProviderQueue',
  FETCH_QUEUE_FOR_COUNTER: 'liveUpdates/CounterQueue',
  FETCH_CUSTOMER_FLOW_BOARD_TODAY: 'customer/flow/board-today',
  SEND_PRESCRIPTION: 'prescription',
  UPDATE_GUEST_INFO: 'guest-users',
  RE_ENTRY_OR_LATE_ENTRY: 're-booking',
  STOP_THE_SERVICE_FOR_TODAY: 'events/stop-service',

  SCHEDULE_REPORT: 'availabilities/temporary/report',

  FETCH_SURVEY_OPTIONS_BY_ASSESSOR_ID: 'surveys/option',
  FIND_SURVEYS_BY_ASSESSOR_ID: 'find-survey-by-assessor-id',
  FIND_SURVEYS_BY_EVENT_ID: 'surveys/event',
  FIND_SURVEY_ANSWERS_BY_EVENT_ID: 'survey-answers/event',

  FETCH_CHART_BY_SURVEY_ID: 'chart',

  FETCH_CUSTOMER_REPORT: 'customer/flow/report',

  RESCHEDULE_BOOKING_EVENT: 'events/reschedule',
  CANCEL_BOOKING_EVENT: 'events/cancel',
  EMAIL_RETRIGGER: 'emails-retrigger',
  DISABLE_BOOKING_SLOTS: 'availabilities',

  ENABLE_BOOKING_SLOTS: 'enableavailabilities',

  FIND_EVENTS_DETAILS_INCLUDING_QUES: 'find-all-events',

  UPDATE_RE_ENTRY_OFFSET: 'update-re-entry-offset',

  CREATE_NEW_BOOKING: 'waitlists-offline',

  TRIGGER_EMAILS: 'temporary-services/trigger-notifications/id',

  CHECK_SCHEDULED_SERVICE_OVERLAP: 'temporary-services-overlap-validate',

  CHECK_SCHEDULED_SERVICE_DELETE_BOOKING_OVERLAP: 'temporary-services-overlap-delete-validate',

  OBTAIN_TWILIO_ACCESS_TOKEN: 'twilio/token',
  OBTAIN_ZOOM_ACCESS_TOKEN: 'zoom/token',

  SMS_TEMPLATES: 'sms-templates',
  SMS_EVENTS: 'sms-event-options',
  FILTER_FEEDBACK_ANSWERS: 'filter-feedback-answers',
  FIND_FEEDBACK_ANSWERS_BY_EVENT_ID: 'feedback-answers-by-eventId',

  PRODUCT_CATEGORIES: 'product-categories',
  PRODUCTS: 'products',
  PRODUCT_CATEGORIES_ORDER: 'save-product-category-order',
  ADD_WHATSAPP_NUMBER: 'add-whatsapp-number',
  WHATSAPP_TEMPLATES: 'whatsapp-templates',

  FETCH_COUNTER_OPTION_BY_BUSINESS_ADMIN_ID: 'counters-option-by-business-admin-id',
  CUSTOMER_BLACKLIST: 'blacklisted-users',
  CUSTOMER_CHECKIN: 'customer-checkIn',
  ZOOM_CREDENTIALS: 'zoom/credentials',
  ORG_PAYMENT_METHODS: 'payment-methods',
  UPDATE_EVENT_PAYMENT_STATUS: 'events/payment-status',
  SUPERADMIN_PREFERENCES: 'superadmin-config',
  NOTIF_LIST: 'notification-list',
  SUPERADMIN_REVIEWS: 'super-admin/reviews',
  ACTIVITY_LOGS: 'activity-logs',
  LOCATION_GROUPS : 'location-groups',
  SUBSCRIPTION_PLANS : 'plans',
};

export const GOOGLE_ID = '166981643559-r54fbu1evv6cpfpphqjtlo4j950vdmvn.apps.googleusercontent.com';

export const GEO_CODING_KEY = 'AIzaSyCM4fZfA-m2F6ekcl4IyN77YZJlAdydxlc';

export const PROD_REDIRECT_URI = REGION_CONFIG[REGION].PROD_REDIRECT_URI;

export const MS_GRAPH_CLIENT_ID = 'a2af90a5-1849-4b44-9c30-e2b7e7e201aa'; //quezone
export const MS_GRAPH_CLIENT_SECRET = 'w_7_lSxCs.l24mMY0_E2g-1HjHSUtT1vFJ'; //quezone

export const MS_GRAPH_CLIENT_ID_SKYPE = '0e735251-dcbd-459e-bb2d-ec442f8f8de9';

export const API_SUBSCRIBE_EVENTS = REGION_CONFIG[REGION].API_SUBSCRIBE_EVENTS;
export const API_SUBSCRIBE_EVENTS_BOARD = REGION_CONFIG[REGION].API_SUBSCRIBE_EVENTS_BOARD; 

export const DEFAULT_TIMEZONE = REGION_CONFIG[REGION].DEFAULT_TIMEZONE;